import React from 'react'

import { List, Divider, Row, Col, Card } from 'antd'

import { LinkOutlined } from '@ant-design/icons'

const { Meta } = Card

export const ResourcesPage = (): JSX.Element => {
  const resourcesList = [
    {
      name: 'CPIC',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/cpic_logo.png',
      link: 'https://cpicpgx.org/',
      description:
            'The Clinical Pharmacogenetics Implementation Consortium (CPIC®) creates and disseminates  evidence-based gene/drug clinical practice guidelines. '
    },
    {
      name: 'PharmGKB',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/pharmgkb_logo.png',
      link: 'https://www.pharmgkb.org/',
      description:
            'The Pharmacogenomics Knowledgebase curates and disseminates knowledge about the impact of human genetic variation on drug responses.'
    },
    {
      name: 'PharmVar',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/pharmvar_logo.png',
      link: 'https://www.pharmvar.org/',
      description:
            'The Pharmacogene Variation Consortium is a repository for pharmacogene variation that focuses on haplotype structure and allelic variation.'
    },
    {
      name: 'FDA 1',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/fda_logo.png',
      link:
            'https://www.fda.gov/drugs/science-and-research-drugs/table-pharmacogenomic-biomarkers-drug-labeling',
      description:
            'The FDA’s <i>Table of Pharmacogenomic Biomarkers in Drug Labeling</i> lists therapeutic products from Drugs@FDA with PGx information found in the drug labeling.'
    },
    {
      name: 'FDA 2',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/fda_logo.png',
      link:
            'https://www.fda.gov/medical-devices/precision-medicine/table-pharmacogenetic-associations',
      description:
            'The FDA’s <i>Table of Pharmacogenetic Associations</i> list phenotype-drug associations that the FDA has evaluated and believes there is sufficient scientific evidence to suggest affected subgroups are likely to have altered drug metabolism or differential therapeutic effects'
    },
    {
      name: 'GTR',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/gtr_logo.png',
      link: 'https://www.ncbi.nlm.nih.gov/gtr/',
      description:
            'The Genetic Testing Registry provides a central location for voluntary submission of genetic test information by providers.'
    },
    {
      name: 'ClinVar',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/clinvar_logo.png',
      link: 'https://www.ncbi.nlm.nih.gov/clinvar/',
      description:
            'ClinVar aggregates information about genomic variation and its relationship to human health.'
    },
    {
      name: 'Ensembl',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/ensembl_logo.png',
      link: 'http://useast.ensembl.org/index.html',
      description:
            'A EMBI-EBI/WTSI resource that annotates the genome, integrating other biological data.'
    },
    {
      name: 'Entrez Gene',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/entrez_logo.png',
      link: 'https://www.ncbi.nlm.nih.gov/gene',
      description: 'NCBI resource that integrates gene information from a wide range of species.'
    },
    {
      name: 'dbSNP',
      imageUrl: 'https://t2l-resources-logos.s3.amazonaws.com/dbsnp_logo.png',
      link: 'https://www.ncbi.nlm.nih.gov/snp/',
      description:
            'The NCBI Short Genetic Sequence database catalogs short variations in nucleotide sequences including SNPs.'
    }
  ]

  return (
        <>
            <Row gutter={[16, 16]}>
                {resourcesList.map((resource, index) => (
                    <Col key={index} sm={12} md={8} xl={6} xxl={4}>
                        <Card
                            className="resource-card"
                            cover={<img alt={resource.name} src={resource.imageUrl} />}
                            actions={[
                            <a href={resource.link} target="_blank" rel="noopener noreferrer">
                                <span>Go To Resource</span>
                                <LinkOutlined key="link" style={{ color: '#1890ff', marginLeft: '10px' }} />
                            </a>
                            ]}
                        >
                            <Meta description={<div dangerouslySetInnerHTML={{ __html: resource.description }} />} />
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
  )
}
