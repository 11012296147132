import React from 'react'
import Layout from 'antd/lib/layout'
import { Card, Row, Col, Space, notification } from 'antd'

import '../App.css'

export const DataSourceCard = ({ title = '', content = '', icon = <></>, onClick = () => { null } }): JSX.Element => {
  return (
        <>
                <Card
                    title={
                        <>
                            {icon}{title}
                        </>
                    }
                    bordered
                    hoverable
                    onClick={onClick}
                    style={{ margin: 8 }}
                >
                    {content}
                </Card>
        </>
  )
}
