import React from 'react'

import { useUser } from '../../context/user_context'

import type { TransferDirection } from 'antd/es/transfer'

import { Button, Transfer, Row, Col, Divider, notification } from 'antd'

import { UpdateLookupGenes, UpdateInterpretGenes, UpdateTranslationTableGenes, GetUserData } from '../../api'

import { useNavigate } from 'react-router-dom'

import { type RecordType } from '../../@types/misc'

export const TranslationTableGenesSelect = (): JSX.Element => {
  const { user, updateUser } = useUser()

  const availableTranslateGenes: RecordType[] = user!.lti_course.institution.translation_table_genes.map((gene) => ({ key: gene.gene, title: gene.gene, description: '' }))
  const initialTranslateGenes: string[] = user!.lti_course.translation_table_genes.map((gene) => gene.gene)

  const [targetCourseTranslateGenes, setTargetCourseTranslateGenes] = React.useState(initialTranslateGenes)
  const [selectedTargetTranslateGenes, setSelectedTargetTranslateGenes] = React.useState<string[]>([])

  const onTranslateGeneChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    setTargetCourseTranslateGenes(nextTargetKeys)
    UpdateTranslationTableGenes(nextTargetKeys)
  }

  const onTranslateGeneSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedTargetTranslateGenes([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  const onTranslateGeneSubmit = () => {
    UpdateTranslationTableGenes(targetCourseTranslateGenes).then(() => {
      notification.success({ message: 'Translation table genes updated' })
      GetUserData().then((user) => {
        updateUser(user)
      }).catch((error) => {
        notification.error({ message: 'Error updating translation table genes' })
      })
    })
  }

  return (
        <>
            <Divider orientation="left">Select genes available in the translation table activity</Divider>
            <Row
            gutter={[24, 0]}
            justify="space-around">
                <Col span={12}>
                    <Transfer
                        showSearch
                        titles={['Genes Available', 'Genes Selected']}
                        dataSource={availableTranslateGenes}
                        targetKeys={targetCourseTranslateGenes}
                        selectedKeys={selectedTargetTranslateGenes}
                        render={item => item.title}
                        onChange={onTranslateGeneChange}
                        onSelectChange={onTranslateGeneSelectChange}
                        rowKey={record => record.key}
                        listStyle={{
                          width: 300,
                          height: 300
                        }}
                        />
                <br />
                <Button type="primary" onClick={onTranslateGeneSubmit}>Submit Changes</Button>
                </Col>
            </Row>
        </>
  )
}
