import React from "react"

import { useUser } from "../../context/user_context"

import { useNavigate } from "react-router-dom"

import { Divider, Row, Col, Transfer, Button, notification, Radio, Spin, Space, Table } from "antd"

import { UpdatePharmacogenomicsReportGenes, UpdatePharmacogenomicsReportSettings, GetUserData } from '../../api'

import type { TransferDirection } from 'antd/es/transfer'

import {READ_ONLY, SELECT_BLANK, SELECT_CORRECT, SELECT_INCORRECT} from "../../constants/constants"

export const PharmacogenomicsReportGenesSelect = (): JSX.Element => {
  const { user, updateUser } = useUser()

  const availablePharmacogenomicsReportGenes = user!.lti_course.institution.pharmacogenomics_report_genes.map(gene => ({
    key: gene.gene,
    title: gene.gene,
    description: ''
  }));

  const initialPharmacogenomicsReportGenes: string[] = user!.lti_course.pharmacogenomics_report_genes.map((gene) => gene.gene)

  const [ targetCoursePharmacogenomicsReportGenes, setTargetCoursePharmacogenomicsReportGenes ] = React.useState(initialPharmacogenomicsReportGenes)
  const [ selectedTargetPharmacogenomicsReportGenes, setSelectedTargetPharmacogenomicsReportGenes ] = React.useState<string[]>([])

  const [ geneSelections, setGeneSelections ] = React.useState<{ [key: string]: number }>({});

  const [ isLoading, setIsLoading ] = React.useState(true)
  const [ showResult, setShowResult ] = React.useState(false)

  const onPharmacogenomicsReportGeneChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    setTargetCoursePharmacogenomicsReportGenes(nextTargetKeys)
  }

  const onPharmacogenomicsReportGeneSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedTargetPharmacogenomicsReportGenes([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  const onPharmacogenomicsReportGeneSubmit = () => {
    UpdatePharmacogenomicsReportGenes(targetCoursePharmacogenomicsReportGenes).then(() => {
      notification.success({ message: 'Pharmacogenomics report genes updated' })
      GetUserData().then((user) => {
        updateUser(user)
      }).catch((error) => {
        notification.error({ message: 'Error updating pharmacogenomics report genes' })
      })
    })
  }

  const onPharmacogenomicsReportPhenotypeSubmit = () => {
    UpdatePharmacogenomicsReportSettings(geneSelections).then(() => {
      notification.success({ message: 'Pharmacogenomics report settings updated' });
      GetUserData().then((user) => {
        updateUser(user);
      }).catch((error) => {
        notification.error({ message: 'Error updating pharmacogenomics report settings' })
      })
    });
  };

  const onRadioChange = (gene: string, value: number) => {
    setGeneSelections({
      ...geneSelections,
      [gene]: value,
    });
  };

  const phenotypeSettingColumns = [
    {
      title: "",
      dataIndex: "gene",
      key: "gene",
      align: "right" as const,
    },
    {
      title: "Read-only Report",
      align: "center" as const,
      children:
      [
        {
          title: "Correct Phenotype",
          dataIndex: "readOnly",
          key: "readOnly",
          align: "center",
        }
      ]
    },
    {
      title: "Dynamic Report - Choose what is defaulted",
      children:
      [
        {
          title: "Blank Phenotype",
          dataIndex: "blank",
          key: "blank",
          align: "center",
        },
        {
          title: "Correct Phenotype",
          dataIndex: "correct",
          key: "correct",
          align: "center",
        },
        {
          title: "Incorrect Phenotype",
          dataIndex: "incorrect",
          key: "incorrect",
          align: "center",
        },
      ]
    }
  ];

  const geneData = user!.lti_course.institution.pharmacogenomics_report_genes
    .filter(gene => initialPharmacogenomicsReportGenes.includes(gene.gene))
    .map((gene) => ({
    key: gene.gene,
    gene: gene.gene,
    readOnly: (
      <Radio
        checked={geneSelections[gene.gene] === READ_ONLY}
        onChange={() => onRadioChange(gene.gene, READ_ONLY)}
      />
    ),
    blank: (
      <Radio
        checked={geneSelections[gene.gene] === SELECT_BLANK}
        onChange={() => onRadioChange(gene.gene, SELECT_BLANK)}
      />
    ),
    correct: (
      <Radio
        checked={geneSelections[gene.gene] === SELECT_CORRECT}
        onChange={() => onRadioChange(gene.gene, SELECT_CORRECT)}
      />
    ),
    incorrect: (
      <Radio
        checked={geneSelections[gene.gene] === SELECT_INCORRECT}
        onChange={() => onRadioChange(gene.gene, SELECT_INCORRECT)}
      />
    ),
  }));

  const selectionSettings = () => {
    return (
      <>
        <Table
          columns={phenotypeSettingColumns}
          dataSource={geneData}
          size="small"
          pagination={false}
        />
      </>
    )
  }

  React.useEffect(() => {
    setGeneSelections(user!.lti_course.pharmacogenomics_report_settings);
    setShowResult(true)
    setIsLoading(false)
  }, [user])

  return (
    <>
        <Divider orientation="left">Select genes available in the pharmacogenomics report</Divider>
        <Row
        gutter={[24, 0]}
        justify="space-around">
            <Col span={12}>
                <Transfer
                    showSearch
                    titles={['Genes Available', 'Genes Selected']}
                    dataSource={availablePharmacogenomicsReportGenes}
                    targetKeys={targetCoursePharmacogenomicsReportGenes}
                    selectedKeys={selectedTargetPharmacogenomicsReportGenes}
                    render={item => item.title}
                    onChange={onPharmacogenomicsReportGeneChange}
                    onSelectChange={onPharmacogenomicsReportGeneSelectChange}
                    rowKey={record => record.key}
                    listStyle={{
                      width: 300,
                      height: 300
                    }}
                    />
            <br />
            <Button type="primary" onClick={onPharmacogenomicsReportGeneSubmit}>Submit Changes</Button>
            </Col>
        </Row>

        <Divider orientation="left">Select genes phenotype in the pharmacogenomics report</Divider>
        {isLoading
          ? <Row gutter={[24, 0]} justify="space-around">
        <Col span={12}>
        <Spin />
        </Col>
        </Row>
          : null}
        {showResult &&
          <Row
          gutter={[24, 0]}
          justify="space-around" >
              <Col span={20} offset={2}>
                  {selectionSettings()}
              <br />
              <Button type="primary" onClick={onPharmacogenomicsReportPhenotypeSubmit}>Submit Changes</Button>
              </Col>
          </Row>
        }
    </>
  )
}