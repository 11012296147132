import cpic_json_data from '../data/cpic_api_diplotype.json'
import phenotypes_json_data from '../data/unique_phenotypes.json'


interface CpicDataMapType {
  [key: string]: string;
}
const cpicDataMap: CpicDataMapType = {};

for (const entry of cpic_json_data) {
    const key = `${entry.genesymbol}-${entry.diplotype}`;
    cpicDataMap[key] = entry.generesult;
}

// Define an interface for the phenotypes data structure
interface PhenotypesDataMapType {
  [gene: string]: string[];
}

const phenotypesDataMap: PhenotypesDataMapType = {};

// Assuming phenotypes_json_data is defined elsewhere
for (const [gene, phenotypes] of Object.entries(phenotypes_json_data)) {
  phenotypesDataMap[gene] = phenotypes;
}


export { cpicDataMap, phenotypesDataMap };
