import React from 'react'

import { Row, Col, Space, notification, Modal, Button, type ModalProps } from 'antd'

import '../../App.css'

import { GetEulaText, AcceptEula } from '../../api'

import { useUser } from '../../context'

import { useNavigate } from 'react-router-dom'

import { type DefaultModalProps } from '../../@types/misc'

const default_props: DefaultModalProps = {
  link_text: null,
  show: false
}

export const Eula = (): JSX.Element => {
  const [eula, setEula] = React.useState('')

  GetEulaText().then((res) => {
    setEula(res.data)
  })

  return (
        <>
            <div dangerouslySetInnerHTML={ { __html: eula } } />
        </>
  )
}

export const EulaModal = ({ show = default_props.show, link_text = default_props.link_text }): JSX.Element => {
  const [modal, contextHolder] = Modal.useModal()
  // initialize with show props
  const [showModal, setShowModal] = React.useState(show)

  const { user, updateUser } = useUser()

  const navigate = useNavigate()

  const modal_props: ModalProps = {
    title: 'End User License Agreement',
    open: showModal
  }

  const modalStyles = {
    body: {
      padding: '0px',
      overflow: 'scroll',
      height: '300px',
      margin: '25px'
    }
  }

  const acceptEula = () => {
    AcceptEula().then((res) => {
      if (res.status === 200) {
        user!.eula_accepted = true
        updateUser(user!)
        setShowModal(false)
        navigate('/')
      } else {
        notification.error({
          message: 'Error',
          description: 'Error accepting EULA'
        })
      }
    })
  }

  const declineEula = () => {
    setShowModal(false)
    window.close()
  }

  if (!user?.eula_accepted) {
    // cancel button to decline
    modal_props.cancelText = 'Decline'
    modal_props.okText = 'Accept'
    modal_props.cancelButtonProps = {
      onClick: declineEula,
      danger: true
    }
    modal_props.okButtonProps = {
      type: 'primary',
      onClick: acceptEula
    }
  } else {
    // hide ok button
    modal_props.okButtonProps = {
      style: { display: 'none' }
    }
    // cancel button closes
    modal_props.cancelButtonProps = {
      onClick: () => {
        setShowModal(false)
      }
    }
    // x in header closes
    modal_props.onCancel = () => {
      setShowModal(false)
    }
  }

  return (
        // show linke text if link text not null
        <>
            {link_text && <a onClick={() => { setShowModal(true) }}>{link_text}</a>}
            <Modal
                {...modal_props}
                styles={modalStyles}
                width="800px"
            >
                <Eula />
            </Modal>
        </>
  )
}
