import React from 'react'
import logo from '../../assets/logo.svg'

import { Button } from 'antd'

import { Link } from 'react-router-dom'

export const BaseHeader = (): JSX.Element => {
  return (
        <div className="header">
            <div>
                <Link to="/">
                    <img className="header-logo" src={logo} alt="Test2Learn" />
                </Link>
            </div>
        </div>
  )
}
