import React from 'react'

import { useDataSource } from '../../context/data_source_context'
import { GetVariantsById } from '../../api'

import { Space, Spin, Row, Col, Divider, List, Button } from 'antd'

export const LookupVariant = () => {
  const [lookupText, setLookupText] = React.useState('')
  const [result, setResult] = React.useState([])
  const [showResult, setShowResult] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const { dataSource } = useDataSource()

  // if submit, don't reload
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setShowResult(false)
    setIsLoading(true)
    lookupVariant(lookupText)
  }

  const lookupVariant = (lookup: string) => {
    const data_source = (dataSource != null) ? dataSource.data_source : ''
    GetVariantsById(data_source, [lookup]).then((res) => {
      setIsLoading(false)
      setResult(res)
      setShowResult(true)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLookupText(e.target.value)
  }

  React.useEffect(() => {
    if (showResult) {
      setShowResult(false)
      setIsLoading(true)
      lookupVariant(lookupText)
    }
  }, [dataSource])

  return (
        <>
            <Divider orientation="left" >Lookup Variant</Divider>
            <Row
            gutter={[24, 0]}
            justify="space-around">
                <Col span={12}>
                    <form onSubmit={handleSubmit}>
                        <label>
                        <input
                        type="text"
                        value={lookupText}
                        onChange={handleChange}
                        placeholder="Example: rs4244285"
                        className="field-size-lg" />
                        </label>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </form>
                </Col>
            </Row>
            {isLoading
              ? <Row gutter={[24, 0]} justify="space-around">
            <Col span={12}>
            <Spin />
            </Col>
            </Row>
              : null}
            {showResult && result.length >= 0 &&
            <>
                <Divider orientation="left">Results</Divider>
                <Row gutter={[24, 0]} justify="space-around">
                  <Col span={12}>
                    {result.length == 0 ? (
                      <span style={{ color: "grey" }}>Variant was not found in the currently loaded data.</span>
                    ) : (
                      result.map((variant) => {
                        return (
                          <List bordered>
                            <List.Item>
                              <b>Variant ID:</b> {variant['var_id']}
                            </List.Item>
                            <List.Item>
                              <b>Chromosome:</b> {variant['contig']}
                            </List.Item>
                            <List.Item>
                              <b>Position:</b> {variant['position']}
                            </List.Item>
                            <List.Item>
                              <b>Genotype:</b> {variant['genotype']}
                            </List.Item>
                          </List>
                        )
                      })
                    )}
                  </Col>
                </Row>
            </>
            }
        </>
  )
}
