import React from 'react'
import Layout from 'antd/lib/layout'
import { Row, Col, Space, notification, Modal, Button } from 'antd'

import '../../App.css'

import config from '../../Config.json'

import { useDataSource, useUser } from '../../context'
import { GetNewAnonDataId, CheckAnonDataId } from '../../api'

import { GetVariantsByGene, GetVariantsById } from '../../api/test2learn'
import { clear } from 'console'

import { DatabaseTwoTone } from '@ant-design/icons'

export const DataSelectModal = ({ title = '', content = '', icon = <></>, data_key = '' }): JSX.Element => {
  const [modal, contextHolder] = Modal.useModal()
  const [showModal, setShowModal] = React.useState(false)
  const [showSelectSpecificModal, setShowSelectSpecificModal] = React.useState(false)
  const [dataIdText, setDataIdText] = React.useState('')
  const [dataIdError, setDataIdError] = React.useState(false)

  const { dataSource, setDataSource, setTtamAuthed, setTtamData, setAnonymousDataId, setAnonymousData, clearAllDataSources } = useDataSource()
  const { user } = useUser()

  const clear_data = () => {
    const wnd = window.open('https://api.23andme.com/logout/', '_blank', 'width=700, height=750') as Window
    setTimeout(() => {
      wnd.close()
      clearAllDataSources()
    }, 500)
  }

  const sourceDisplay = () => {
    if (dataSource?.data_source === 'ttam') {
      return ('23andMe')
    } else {
      return (`Anonymous (${dataSource?.anon_data_id})`)
    }
  }

  const ttamSetData = () => {
    if (!dataSource?.ttam_is_set) {
      window.open(
                `https://api.23andme.com/authorize/?redirect_uri=${config.API_URL}/user/receive_23andMe_code/&response_type=code&client_id=${config.TTAM_CLIENT_ID}&scope=basic names email genomes&select_profile=True`,
                '_blank',
                'popup,width=500,height=750'
      )
    } else {
      setTtamData()
    }
  }

  const anonSetData = () => {
    if (dataSource?.anon_data_id === '') {
      GetNewAnonDataId().then(data => {
        setAnonymousDataId(data.dataset_id)
      }).catch(error => {
        console.log(error)
      })
    } else {
      setAnonymousData()
    }
  }

  const selectNewAnonData = () => {
    GetNewAnonDataId().then(data => {
      setAnonymousDataId(data.dataset_id)
    }).catch(error => {
      console.log(error)
    })
  }

  // const contributeData = () => {
  //   ContributeData().then(data => {
  //     // show modal with success message
  //     notification.success({
  //       message: data.message
  //     })
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // }


  const handleDataIdSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    CheckAnonDataId(dataIdText).then((res) => {
      if (res) {
        setAnonymousDataId(dataIdText)
        setDataIdText('')
        setShowSelectSpecificModal(false)
      } else {
        setDataIdError(true)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleDataIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataIdError(false)
    setDataIdText(e.target.value)
  }

  const handleCancel = () => {
    setDataIdText('')
    setShowSelectSpecificModal(false)
  }

  const useTtamData = () => {
    // is the user allowed to use 23andMe data?
    // check if "ttam" key is in user's data_sources
    let ttam_allowed = false
    const data_sources = user?.lti_course.data_sources
    if (data_sources) {
      // is one of the data source object contain the ky 'ttam'?
      data_sources.forEach((ds: any) => {
        if (ds.key === 'ttam') {
          ttam_allowed = true
        }
      })
    }
    return ttam_allowed
  }

  // const contributeTtamData = () => {
  //   // is the user allowed to contribute 23andMe data?
  //   // check if 'contib_data' key is in user's data_sources
  //   let contrib_data_allowed = false
  //   const lti_course = user?.lti_course
  //   if (lti_course) {
  //     contrib_data_allowed = lti_course.contribute_data
  //   }
  //   return contrib_data_allowed
  // }

  return (
        <>
            <DatabaseTwoTone
                className = "data-select-modal-icon"
                onClick={() => {
                  setShowModal(true)
                }}>Show Modal</DatabaseTwoTone>

            <Modal
                title="Select/Change Your Dataset"
                open={showModal}
                onCancel={() => {
                  setShowModal(false)
                }}
                onOk={() => {
                  setShowModal(false)
                }}
            >
                <Row>
                    <Col span={12}>
                        <h3>Current Data Source: {sourceDisplay()}</h3>
                    </Col>
                </Row>
                {useTtamData()
                  ? (
                    <>
                      <Row>
                          <Col span={6}>
                              <Button onClick={() => { ttamSetData() }}>
                                  23andMe
                              </Button>
                          </Col>
                          {/* {contributeTtamData()
                            ? (
                              <Col span={6}>
                                  <Button onClick={() => { contributeData() }}>
                                      Contribute Data
                                  </Button>
                              </Col>
                              ) : (
                                <></>
                            )} */}
                      </Row>
                    </>
                    ) 
                    : (
                      <></>
                )}
                <Row>
                    <Col span={6}>
                        <Button onClick={() => { anonSetData() }}>
                            Anonymous
                        </Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={() => { selectNewAnonData() }}>
                            Select New
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => {
                          setShowSelectSpecificModal(true)
                          setDataIdError(false)
                        }}>
                          Select Specific
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Button onClick={() => { clear_data() }}>
                            Clear Data
                        </Button>
                    </Col>
                </Row>
            </Modal>
            {showSelectSpecificModal && (
              <Modal
                title="Select Specific Anonymous Data"
                open={showSelectSpecificModal}
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cencel
                  </Button>,
                ]}
              >
                <form onSubmit={handleDataIdSubmit}>
                  <label>
                    <input
                      type="text"
                      value={dataIdText}
                      onChange={handleDataIdChange}
                      placeholder={(dataSource != null) ? dataSource.anon_data_id : 'HG00100'}
                      className="field-size-lg"
                    />
                  </label>
                  <Button type="primary" htmlType="submit">Submit</Button>
                  {dataIdError && (
                    <div style={{color: 'red'}}><i>The anonymous data ID is not valid.</i></div>
                  )}
                </form>
              </Modal>
            )}
        </>
  )
}
