import React from 'react'

import { useDataSource } from '../context'

import { GetNewAnonDataId, GetTtamAuth } from '../api'

import { Link, useNavigate } from 'react-router-dom'

export const DataSourceManager = (): JSX.Element => {
  const navigate = useNavigate()

  const { dataSource, setDataSource, setTtamAuthed, setTtamData, setAnonymousDataId, clearAllDataSources, setDataSourceFromCache } = useDataSource()

  React.useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      switch (e.key) {
        case 'ttam_auth': {
          if (e.newValue === 'true') {
            setTtamAuthed()
            // delete the item from localstorage
            localStorage.removeItem('ttam_auth')
          }
          break
        }
      }
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  })

  // listen for page refresh
  // React.useEffect(() => {
  //     setDataSourceFromCache();
  // }, []);

  // listen for window close
  React.useEffect(() => {
    const handleWindowClose = () => {
      clearAllDataSources()
    }
    window.addEventListener('beforeunload', handleWindowClose)
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
  }, [])

  return (
        <>
        </>
  )
}
