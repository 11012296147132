import React from 'react'
import { Link } from 'react-router-dom'
import Layout from 'antd/lib/layout'
import { Row, Col } from 'antd'

import '../../App.css'

import ttamLogo from '../../assets/ttam_logo.png'
import pgpLogo from '../../assets/upitt_logo.png'

import { EulaModal, TermsModal, PrivacyModal } from '../legal'

export const BaseFooter = (): JSX.Element => {
  return (
    <>
      <div className="app-footer-container">
        <Layout className="app-footer">
          <Row>
            <Col span={24}>
            <span className="footer-link">
              <TermsModal link_text={'Terms of Use'} />
              </span>{' '}
              |{' '}
              <span className="footer-link">
                <PrivacyModal link_text={'Privacy Policy'} />
              </span>{' '}
              |{' '}
              <span className="footer-link">
                <EulaModal link_text={'End User License Agreement'}/>
              </span>
            </Col>
            <Col span={24}>
              Powered by <img className="bigger-image" src={pgpLogo} alt="PittPharmacy" /> in
              collaboration with <img alt="23andMe" className="logo" src={ttamLogo} />
            </Col>
            <Col span={24}>
              &copy; {new Date().getFullYear()} University of Pittsburgh School of Pharmacy. All Rights
              Reserved.
            </Col>
          </Row>
        </Layout>
      </div>
    </>
  )
}
