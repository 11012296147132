import React from 'react'

import { useDataSource } from '../../context/data_source_context'
import { GetVariantsByGene, TranslateSample } from '../../api'

import { Space, Spin, Table, Divider, Row, Col, Button } from 'antd'

import { useUser } from '../../context/user_context'
import { replaceUnderscoreWithHyphen } from '../activities/translation_table';

export const InterpretGene = () => {
  const [lookupGene, setLookupGene] = React.useState('')
  const [tableData, setTableData] = React.useState([] as any[])
  const [variantTableData, setVariantTableData] = React.useState([] as any[])
  const [showResult, setShowResult] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const { dataSource } = useDataSource()
  const { user } = useUser()

  const table_columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value'
    }
  ]

  const variant_table_columns = [
    {
      title: 'Variant',
      dataIndex: 'variant',
      key: 'variant'
    },
    {
      title: 'Chromosome',
      dataIndex: 'chromosome',
      key: 'chromosome'
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position'
    },
    {
      title: 'Alt Allele',
      dataIndex: 'alt_allele',
      key: 'alt_allele'
    },
    {
      title: 'Genotype',
      dataIndex: 'genotype',
      key: 'genotype'
    }
  ]

  // if submit, don't reload
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    setShowResult(false)
    translateGene(lookupGene)
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLookupGene(e.target.value)
  }

  // update results if posted and user changes data source
  React.useEffect(() => {
    if (showResult) {
      setShowResult(false)
      setIsLoading(true)
      translateGene(lookupGene)
    }
  }, [dataSource])

  const translateGene = (lookup: string) => {
    const data_source = (dataSource != null) ? dataSource.data_source : ''
    TranslateSample(data_source, lookup, 'GRCH38').then(res => {
      const table_data = [
        {
          item: 'Gene',
          value: lookup,
          key: 1
        },
        {
          item: 'Diplotype',
          value: replaceUnderscoreWithHyphen(res.diplotype),
          key: 2
        },
        {
          item: 'Activity Score',
          value: res.activity_score,
          key: 3
        },
        {
          item: 'Predicted Phenotype',
          value: res.phenotype,
          key: 4
        }
      ]
      setTableData(table_data)
      const variant_table = res.variants.map((variant: any, index: number) => {
        return {
          variant: variant.var_id,
          chromosome: variant.contig,
          position: variant.position,
          genotype: variant.genotype,
          alt_allele: variant.allele,
          key: index
        }
      })
      setVariantTableData(variant_table)
      setIsLoading(false)
      setShowResult(true)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
        <>
            <Divider orientation="left" >Call and Interpret Haplotypes in a Gene</Divider>
            <Row
            gutter={[24, 0]}
            justify="space-around">
                <Col span={12}>
                    <form onSubmit={handleSubmit}>
                        <label>
                        <select onChange={handleChange} className="field-size-lg">
                            <option value="" key="None"></option>
                            {user?.lti_course.interpret_genes.map((gene, index) => {
                              return (
                                    <option value={gene.gene} key={gene.id}>{gene.gene}</option>
                              )
                            })}
                        </select>
                        </label>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </form>
                </Col>
            </Row>
            {isLoading
              ? <Row gutter={[24, 0]} justify="space-around">
            <Col span={12}>
            <Spin />
            </Col>
            </Row>
              : null}
            {showResult &&
                <>
                    <Divider orientation="left" >Diplotype and Phenotype Information</Divider>
                    <Row gutter={[24, 0]} justify="space-around">
                        <Col span={12}>
                            <Table
                                columns={table_columns}
                                showHeader={false}
                                dataSource={tableData}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                     <Divider orientation="left">Variants used for this Diplotype</Divider>
                    <Row gutter={[24, 0]} justify="space-around">
                        <Col span={12}>
                            <Table
                                columns={variant_table_columns}
                                dataSource={variantTableData}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                </>
            }
        </>
  )
}
