import { createContext, useContext, useMemo, useState } from 'react'
import { type DataSourceContextInterface, type DataSourceContextType } from '../@types/data_source'

const DataSourceContext = createContext<DataSourceContextType | null>(null)

export const useDataSource = () => {
  return (useContext(DataSourceContext) as DataSourceContextType)
}

export const DataSourceProvider = ({ children }: { children: React.ReactNode }) => {
  const [dataSource, setDataSource] = useState<DataSourceContextInterface | null>(null)

  const setTtamAuthed = () => {
    const anon_data_id = (dataSource != null) ? dataSource.anon_data_id : ''
    const newDataSource = { ttam_is_set: true, data_source: 'ttam', anon_data_id }
    setDataSource(newDataSource)
    localStorage.setItem('data_source_cache', JSON.stringify(newDataSource))
  }

  const setAnonymousDataId = (anon_data_id: string) => {
    const ttam_is_set = (dataSource != null) ? dataSource.ttam_is_set : false
    const newDataSource = { ttam_is_set, data_source: anon_data_id, anon_data_id }
    setDataSource(newDataSource)
    localStorage.setItem('data_source_cache', JSON.stringify(newDataSource))
  }

  const setAnonymousData = () => {
    const anon_data_id = (dataSource != null) ? dataSource.anon_data_id : ''
    const ttam_is_set = (dataSource != null) ? dataSource.ttam_is_set : false
    const newDataSource = { ttam_is_set, data_source: anon_data_id, anon_data_id }
    setDataSource(newDataSource)
    localStorage.setItem('data_source_cache', JSON.stringify(newDataSource))
  }

  const setTtamData = () => {
    const anon_data_id = (dataSource != null) ? dataSource.anon_data_id : ''
    const ttam_is_set = (dataSource != null) ? dataSource.ttam_is_set : false
    const newDataSource = { ttam_is_set, data_source: 'ttam', anon_data_id }
    if (ttam_is_set) {
      setDataSource(newDataSource)
      localStorage.setItem('data_source_cache', JSON.stringify(newDataSource))
    }
  }

  const clearAllDataSources = () => {
    // clear data_source from localstorage
    setDataSource(null)
    localStorage.removeItem('data_source_cache')
  }

  const setDataSourceFromCache = () => {
    const data_source_cache = window.localStorage.getItem('data_source_cache')
    if (data_source_cache) {
      setDataSource(JSON.parse(data_source_cache))
    }
  }

  return (
        <DataSourceContext.Provider value={{ dataSource, setDataSource, setTtamAuthed, setTtamData, setAnonymousDataId, setAnonymousData, setDataSourceFromCache, clearAllDataSources }}>
            {children}
        </DataSourceContext.Provider>
  )
}
