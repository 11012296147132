import React from "react";
import logo from '../../assets/logo.svg'

import { useDataSource } from "../../context/data_source_context";
import { TranslateSample } from '../../api'

import { cpicDataMap, phenotypesDataMap } from '../data/cpic_phenotype';
import { replaceUnderscoreWithHyphen } from '../activities/translation_table';

import { Divider, Table, Collapse, Layout, Space, Row, Col, Spin, Select, Button, notification } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import type { CollapseProps } from 'antd'

import { useUser } from '../../context/user_context'

import { READ_ONLY, SELECT_BLANK, SELECT_CORRECT, SELECT_INCORRECT, NOT_IN_CPIC, SELECT_PLACEHOLDER, ACTIVITY_SCORE_GENES } from "../../constants/constants"

const { Header, Footer, Content } = Layout;

const headerStyle: React.CSSProperties = {
  height: 64,
  paddingInline: 24,
  lineHeight: '64px',
  backgroundColor: '#f2f2f2',
  fontSize: '2vw',
};

const headerLogoStyle: React.CSSProperties = {
  maxHeight: 34,
  maxWidth: 120,
  paddingTop: 15,
  paddingBottom: 15,
  float: 'right',
};

const sessionHeaderStyle: React.CSSProperties = {
  height: '2rem',
  paddingInline: 24,
  lineHeight: '2rem',
  backgroundColor: '#f0f8ff',
  fontWeight: 'bold',
};

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
};

const footerStyle: React.CSSProperties = {
  backgroundColor: '#f2f2f2',
  paddingInline: 24,
};

export const PharmacogenomicsReport = (): JSX.Element => {
    const { dataSource } = useDataSource()
    const { user } = useUser()

    const [ tableData, setTableData ] = React.useState([] as any[])
    const [ isLoading, setIsLoading ] = React.useState(false)
    const [ showResult, setShowResult ] = React.useState(true)

    const [ tableColumns, setTableColumns ] = React.useState([] as any[])
    const [ geneData, setGeneData] = React.useState([] as any[])
    const [ showError, setShowError] = React.useState(false)
    const showErrorRef = React.useRef(false);

    const pharmacogenomics_report_settings = user?.lti_course.pharmacogenomics_report_settings || {};
    
    interface PhenotypeSelectionProps {
        defaultValue: string;
        selectOptions: any[];
        status: string;
    }
    const PhenotypeSelection = (props:PhenotypeSelectionProps) => {
        const { defaultValue, selectOptions, status } = props;
        const _status = status === 'error' ? 'error' : status === 'warning' ? 'warning' : ''
        return (
            defaultValue ? (
                <Select
                    defaultValue={defaultValue}
                    placeholder={SELECT_PLACEHOLDER}
                    onChange={handleChange}
                    options={selectOptions}
                    style={{ width: '100%' }}
                    status={_status}
                />
            ) : (
                <Select
                    placeholder={SELECT_PLACEHOLDER}
                    onChange={handleChange}
                    options={selectOptions}
                    style={{ width: '100%' }}
                    status={_status}
                />
            )

        );
    };

    const handleSubmit = () => {
        showErrorRef.current = false;
        setGeneData((prevGeneData) => prevGeneData.map((item) => {
            if (typeof item.phenotype === 'string') {
                return item;
            }
            const _status = (item.selected === item.correct) ? '' : 'error';
            const _message = (_status === 'error') ? <CloseCircleOutlined style={{ color: 'red' }} /> : null;
            if (_status === 'error') {
                showErrorRef.current = true;
            }
            const _selectOptions = (item.phenotypesData as string[]).map((data: string) => ({
                key: data,
                value: `${item.gene} ${data}`,
                label: `${item.gene} ${data}`,
            }));
            const updatedDefaultValue = (item.selected ? `${item.gene} ${item.selected}` : '');
            return {
                ...item,
                phenotype: <PhenotypeSelection defaultValue={updatedDefaultValue} selectOptions={_selectOptions} status={_status} />,
                message: _message
            };
        }));
        if (showErrorRef.current) {
            notification.error({
                message: 'Incorrect!',
                description: 'You are incorrect - please try again!',
            })
        } else {
            notification.success({
                message: 'Correct!',
                description: 'You are correct!',
            })
        }
    };

    const handleChange = (value: string) => {
        setGeneData((prevGeneData) => {
            return prevGeneData.map((item) => {
                if (item.gene === value.split(' ')[0]) {
                    const updatedSelected = value.replace(`${item.gene} `, '');
                    const _selectOptions = (item.phenotypesData as string[]).map(data => ({
                        key: data,
                        value: `${item.gene} ${data}`,
                        label: `${item.gene} ${data}`,
                    }));
                    const updatedItem = {
                        ...item,
                        selected: updatedSelected,
                        phenotype: <PhenotypeSelection defaultValue={value} selectOptions={_selectOptions} status="" />,
                        message: null,
                    };
                    return updatedItem;
                } else {
                    return item;
                }
            });
        });
    }

    const getCpicGeneresult = (genesymbol: string, diplotype: string) => {
        diplotype = diplotype.replace(/REF/g, "Reference");
        const key = `${genesymbol}-${diplotype}`;
        return cpicDataMap[key] || null;
    };

    const tableGene = async () => {
        const data_source = (dataSource != null) ? dataSource.data_source : ''
        const columns = [
            {
                title: 'Gene',
                dataIndex: 'gene',
                key: 'gene'
            },
            {
                title: 'Diplotype (Activity Score)',
                dataIndex: 'diplotype',
                key: 'diplotype'
            },
            {
                title: 'Predicted Phenotype',
                dataIndex: 'phenotype',
                key: 'phenotype'
            },
            {
                title: "",
                key: "message",
                dataIndex: "message"
            },
            {
                title: "Correct Answer",
                key: "correct",
                dataIndex: "correct",
                hidden: true
            }, 
            {
                title: "Selected Answer",
                key: "selected",
                dataIndex: "selected",
                hidden: true
            },
            {
                title: "Phenotypes Data",
                key: "phenotypesData",
                dataIndex: "phenotypesData",
                hidden: true
            },
            
        ].filter(item => !item.hidden);

        const promises = user?.lti_course.pharmacogenomics_report_genes.map(async (gene) => {
            // functions for each gene    
            const res = await TranslateSample(data_source, gene.gene, 'GRCH38');
            
            // Setup select menu for each gene
            const lookup = gene.gene;
            const phenotypesData = phenotypesDataMap[lookup] || ['N/A'];
            const selectOptions = phenotypesData.map((item) => ({
              value: `${lookup} ${item}`,
              label: `${lookup} ${item}`,
            }));

            // Determine selected phenotype
            const isGeneIncluded = ACTIVITY_SCORE_GENES.includes(lookup);
            const diplotype = replaceUnderscoreWithHyphen(isGeneIncluded ? `${res.diplotype} (${res.activity_score})` : res.diplotype);
            const phenotype = getCpicGeneresult(lookup, res.diplotype);

            if (phenotype === null) {
              return {
                gene: lookup,
                diplotype: diplotype,
                phenotype: NOT_IN_CPIC,
                correct: NOT_IN_CPIC,
                phenotypesData: phenotypesData,
                key: lookup,
              };
            }

            const report_setting = pharmacogenomics_report_settings[lookup] !== undefined ? pharmacogenomics_report_settings[lookup] : READ_ONLY;
            const filteredPhenotypes = phenotypesData.filter((value) => value !== phenotype);
            const defaultPhenotype = report_setting === SELECT_INCORRECT ? (filteredPhenotypes.length > 0 ? filteredPhenotypes[Math.floor(Math.random() * filteredPhenotypes.length)] : phenotype) : phenotype;
            const phenotypeComponent = report_setting === READ_ONLY ? `${lookup} ${phenotype}`
                : report_setting === SELECT_BLANK ? <PhenotypeSelection defaultValue="" selectOptions={selectOptions} status={status} />
                : <PhenotypeSelection defaultValue={`${lookup} ${defaultPhenotype}`} selectOptions={selectOptions} status={status} />;

            return {
              gene: lookup,
              diplotype: diplotype,
              phenotype: phenotypeComponent,
              correct: phenotype,
              selected: (report_setting === SELECT_CORRECT || report_setting === SELECT_INCORRECT) && defaultPhenotype,
              phenotypesData: phenotypesData,
              key: lookup,
              message: null,
            };
        }) || []; 
        const _geneData = await Promise.all(promises);
        setGeneData(_geneData)
        setTableColumns(columns)
        setIsLoading(false);
        setShowResult(true);
        
    };
    const pharmacogenomicsReport = () => {
        return (
            <Table
                columns={tableColumns}
                dataSource={tableData}
                size="small"
                pagination={false}
            />
        )
    }

    React.useEffect(() => {
        setTableData(geneData);
    }, [geneData])

    React.useEffect(() => {
        if (showResult) {
            setShowResult(false);
            setIsLoading(true);
            tableGene();
        }
    }, [dataSource])

    const patient_item: CollapseProps['items'] = [
      {
        key: '1',
        label: 'Patient and order details',
        children: <p>TODO...</p>,
      },
    ];

    const result_item: CollapseProps['items'] = [
      {
        key: '1',
        label: 'Results',
        children:
        <>
            <Content style={contentStyle}>
                {pharmacogenomicsReport()}
            </Content>
            <br />
            <Button type="primary" onClick={handleSubmit}>Submit</Button>
        </>
      },
    ];

    const about_item: CollapseProps['items'] = [
      {
        key: '1',
        label: 'About this test',
        children: <p>TODO...</p>,
      },
    ];

    return (
        <>
            <Divider orientation="left" >Clinical Pharmacogenomics Report</Divider>
            {isLoading
              ? <Row gutter={[24, 0]} justify="space-around">
            <Col span={12}>
            <Spin />
            </Col>
            </Row>
              : null}
            {showResult &&
                <>
                <Row gutter={[24, 0]} justify="space-around" >
                    <Col span={22}>
                        <Space direction="vertical" className="report" size={[0, 20]}>
                            <span style={{ color: 'red', display: 'block', textAlign: 'center' }}>
                                EDUCATION USE ONLY. This is a simulated pharmacogenomics report for learning purposes only. <br />
                                Information cannot be used for clinical care.
                            </span>
                            <Header style={headerStyle}>
                                Clinical Pharmacogenomics Report for sample: {dataSource?.data_source}
                                <img style={headerLogoStyle} src={logo} alt="Test2Learn" />
                            </Header>
                            <Collapse items={patient_item} collapsible="disabled" size="small" style={{ background: '#f0f8ff' }} />
                            <Collapse items={result_item} defaultActiveKey={['1']} size="small" style={{ background: '#f0f8ff' }} />
                            <Collapse items={about_item} collapsible="disabled" size="small" style={{ background: '#f0f8ff' }} />
                            <Footer style={footerStyle}>
                                <span style={{color: 'red'}}>EDUCATION USE ONLY. </span>
                                The information on this website is not intended for direct diagnostic
                                use or medical decision-making without review by a health care professional.
                                Individuals should not change their health behavior solely on the basis of
                                information contained on this website. If you have questions about the
                                information contained on this website, please see a health care professional.
                            </Footer>
                        </Space>
                    </Col>
                </Row>
                </>
            }
        </>
    )
}
