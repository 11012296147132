import React from 'react'

import { useUser } from '../../context/user_context'

import type { TransferDirection } from 'antd/es/transfer'

import { Button, Transfer, Row, Col, Divider, notification } from 'antd'

import { UpdateLookupGenes, UpdateInterpretGenes, UpdateTranslationTableGenes, GetUserData } from '../../api'

import { useNavigate } from 'react-router-dom'

import { type RecordType } from '../../@types/misc'

export const InterpretGenesSelect = (): JSX.Element => {
  const { user, updateUser } = useUser()

  const availableInterpretGenes: RecordType[] = user!.lti_course.institution.interpret_genes.map((gene) => ({ key: gene.gene, title: gene.gene, description: '' }))
  const initialInterpretGenes: string[] = user!.lti_course.interpret_genes.map((gene) => gene.gene)

  const [targetCourseInterpretGenes, setTargetCourseInterpretGenes] = React.useState(initialInterpretGenes)
  const [selectedTargetInterpretGenes, setSelectedTargetInterpretGenes] = React.useState<string[]>([])

  const onInterpretGeneChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    setTargetCourseInterpretGenes(nextTargetKeys)
  }

  const onInterpretGeneSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedTargetInterpretGenes([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  const onInterpretGeneSubmit = () => {
    UpdateInterpretGenes(targetCourseInterpretGenes).then(() => {
      notification.success({ message: 'Interpret genes updated' })
      GetUserData().then((user) => {
        updateUser(user)
      }).catch((error) => {
        notification.error({ message: 'Error updating interpret genes' })
      })
    })
  }

  return (
        <>
            <Divider orientation="left">Select genes available in the interpretation tool</Divider>
            <Row
            gutter={[24, 0]}
            justify="space-around">
                <Col span={12}>
                    <Transfer
                        showSearch
                        titles={['Genes Available', 'Genes Selected']}
                        dataSource={availableInterpretGenes}
                        targetKeys={targetCourseInterpretGenes}
                        selectedKeys={selectedTargetInterpretGenes}
                        render={item => item.title}
                        onChange={onInterpretGeneChange}
                        onSelectChange={onInterpretGeneSelectChange}
                        rowKey={record => record.key}
                        listStyle={{
                          width: 300,
                          height: 300
                        }}
                        />
                <br />
                <Button type="primary" onClick={onInterpretGeneSubmit}>Submit Changes</Button>
                </Col>
            </Row>
        </>
  )
}
