import React from 'react'
import Layout from 'antd/lib/layout'
import { Card, Row, Col, Space, notification } from 'antd'

import { useDataSource, useUser } from '../context'

import { GetNewAnonDataId } from '../api'

import { Outlet, Link, useNavigate, Route, Routes } from 'react-router-dom'

import '../App.css'

import { ActivitySelect, LookupVariant, LookupGene, InterpretGene, TranslationTable, GenomeViewer, PharmacogenomicsReport } from '../components/activities'

type IconComponents = Record<string, JSX.Element>

export const ActivitiesPage = (): JSX.Element => {
  const { user } = useUser()
  const { dataSource } = useDataSource()

  const navigate = useNavigate()

  // if dataSource is null, redirect to home page
  React.useEffect(() => {
    if (dataSource == null) {
      navigate('/')
    }
  }, [dataSource, navigate])

  return (
        <>
            <Routes>
                <Route path="" element={<ActivitySelect />} />
                <Route path="variant-lookup" element={<LookupVariant />} />
                <Route path="gene-lookup" element={<LookupGene />} />
                <Route path="genome-viewer" element={<GenomeViewer />} />
                <Route path="translation-table" element={<TranslationTable />} />
                <Route path="gene-interpret" element={<InterpretGene />} />
                <Route path="pharmacogenomics-report" element={<PharmacogenomicsReport />} />
            </Routes>
            <Outlet />
        </>
  )
}
