import React from 'react'

import { useUser } from '../context/user_context'

import { List, Divider, Row, Col } from 'antd'

export const CourseInfoPage = (): JSX.Element => {
  const { user } = useUser()

  return (
        <>
            <Divider orientation="left">Course Information</Divider>
            <Row
                gutter={[24, 0]}
                justify="space-around">
                    <Col span={12}>
                        <List>
                            <List.Item>
                                <List.Item.Meta
                                    title="Institution"
                                    description={user?.lti_course.institution.name}
                                />
                            </List.Item>
                            <List.Item>
                                <List.Item.Meta
                                    title="Course Name"
                                    description={user?.lti_course.name}
                                />
                            </List.Item>
                            <List.Item>
                                <List.Item.Meta
                                    title="Course Contact"
                                    description={user?.lti_course.contact}
                                />
                            </List.Item>
                            <List.Item>
                                <List.Item.Meta
                                    title="Course Contact Email"
                                    description={user?.lti_course.contact_email}
                                />
                            </List.Item>
                        </List>
                    </Col>
            </Row>

        </>
  )
}
