import React from 'react'

import { Row, Col, Space, notification, Modal, Button, type ModalProps } from 'antd'

import '../../App.css'

import { GetPrivacyText, AcceptPrivacy } from '../../api'

import { useUser } from '../../context'

import { useNavigate } from 'react-router-dom'

import { type DefaultModalProps } from '../../@types/misc'

const default_props: DefaultModalProps = {
  link_text: null,
  show: false
}

export const Privacy = (): JSX.Element => {
  const [privacy, setPrivacy] = React.useState('')

  GetPrivacyText().then((res) => {
    setPrivacy(res.data)
  })

  return (
        <>
            <div dangerouslySetInnerHTML={ { __html: privacy } } />
        </>
  )
}

export const PrivacyModal = ({ show = default_props.show, link_text = default_props.link_text }): JSX.Element => {
  const [modal, contextHolder] = Modal.useModal()
  // initialize with show props
  const [showModal, setShowModal] = React.useState(show)

  const { user, updateUser } = useUser()

  const navigate = useNavigate()

  const modal_props: ModalProps = {
    title: 'Privacy Policy',
    open: showModal
  }

  const modalStyles = {
    body: {
      padding: '0px',
      overflow: 'scroll',
      height: '300px',
      margin: '25px'
    }
  }

  const acceptPrivacy = () => {
    AcceptPrivacy().then((res) => {
      if (res.status === 200) {
        user!.privacy_accepted = true
        updateUser(user!)
        setShowModal(false)
        navigate('/')
      } else {
        notification.error({
          message: 'Error',
          description: 'Error accepting EULA'
        })
      }
    })
  }

  const declinePrivacy = () => {
    setShowModal(false)
    window.close()
  }

  if (!user?.privacy_accepted) {
    // cancel button to decline
    modal_props.cancelText = 'Decline'
    modal_props.okText = 'Accept'
    modal_props.cancelButtonProps = {
      onClick: declinePrivacy,
      danger: true
    }
    modal_props.okButtonProps = {
      type: 'primary',
      onClick: acceptPrivacy
    }
  } else {
    // hide ok button
    modal_props.okButtonProps = {
      style: { display: 'none' }
    }
    // cancel button closes
    modal_props.cancelButtonProps = {
      onClick: () => {
        setShowModal(false)
      }
    }
    // x in header closes
    modal_props.onCancel = () => {
      setShowModal(false)
    }
  }

  return (
        <>
            {link_text && <a onClick={() => { setShowModal(true) }}>{link_text}</a>}
            <Modal
                {...modal_props}
                styles={modalStyles}
                width="800px"
            >
                <Privacy />
            </Modal>
        </>
  )
}
