import React from 'react'
import Layout from 'antd/lib/layout'
import { Card, Row, Col, Space, notification } from 'antd'
import {
  EyeInvisibleOutlined,
  ApiOutlined,
  CloudUploadOutlined,
  LoginOutlined
} from '@ant-design/icons'

import { DataSourceCard } from '../components/data_source_card'

import { useDataSource, useUser } from '../context'

import { GetNewAnonDataId } from '../api'

import config from '../Config.json'

import '../App.css'

import { Link, useNavigate } from 'react-router-dom'

type IconComponents = Record<string, JSX.Element>

export const DataSourceSelectPage = (): JSX.Element => {
  const { dataSource, setDataSource, setTtamAuthed, setTtamData, setAnonymousDataId, clearAllDataSources } = useDataSource()
  const { user } = useUser()

  const navigate = useNavigate()

  const icon_components: IconComponents = {
    eye_invisible: <EyeInvisibleOutlined />,
    api: <ApiOutlined />,
    cloud_upload: <CloudUploadOutlined />,
    login: <LoginOutlined />
  }

  const set_data = (data_key: string) => {
    switch (data_key) {
      case 'ttam': {
        window.open(
                    `https://api.23andme.com/authorize/?redirect_uri=${config.API_URL}/user/receive_23andMe_code/&response_type=code&client_id=${config.TTAM_CLIENT_ID}&scope=basic names email genomes&select_profile=True`,
                    '_blank',
                    'popup,width=500,height=750'
        )
        break
      }
      case 'anon': {
        GetNewAnonDataId().then((data) => {
          setAnonymousDataId(data.dataset_id)
        })
        break
      }
    }
  }

  React.useEffect(() => {
    if (dataSource != null) {
      navigate('/activities')
    }
  }, [dataSource, navigate])

  return (
        <>
            <Row>
                {user?.lti_course.data_sources.map((card, index) => {
                  return (
                        <Col key={index} span={8} className="gutter-row">
                            <DataSourceCard
                                title={card.title}
                                content={card.content}
                                icon={icon_components[card.icon]}
                                onClick={() => {
                                  set_data(card.key)
                                }
                                }
                                />
                        </Col>
                  )
                }

                )}
            </Row>
        </>
  )
}
