import { createContext, useContext, useState } from 'react'
import { type UserContextInterface, type UserContextType } from '../@types/user'

const UserContext = createContext<UserContextType | null>(null)

export const useUser = () => {
  return (useContext(UserContext) as UserContextType)
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserContextInterface | null>(null)
  const updateUser = (user_data: UserContextInterface) => {
    setUser(user_data)
  }
  return (
        <UserContext.Provider value={{ user, updateUser }}>
            {children}
        </UserContext.Provider>
  )
}
