import React from 'react'
import './App.css'

import { Route, Routes, useNavigate } from 'react-router-dom'

import { Header, Footer, BaseHeader, BaseFooter } from './components/layout'

import { DataSourceSelectPage } from './pages/data_source_select_page'

import { useUser, useDataSource } from './context'

import { ActivitiesPage } from './pages/activities_page'

import { CourseInfoPage } from './pages/course_info_page'

import { InstructorPage } from './pages/instructor_page'

import { ResourcesPage } from './pages/resources_page'

const App = (): JSX.Element => {
  const { user } = useUser()
  const { dataSource } = useDataSource()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (dataSource == null) {
      navigate('/')
    }
  }, [dataSource])

  return (
        <>
            {user?.lti_course != null
              ? (
                <>
                    {user?.eula_accepted && user?.terms_accepted && user?.privacy_accepted
                      ? (
                        <>
                            <div className="app-container">
                                <Header />
                                <div className="app-content">
                                    <Routes>
                                        <Route path="/" element={<DataSourceSelectPage />} />
                                        <Route path="/course-info" element={<CourseInfoPage />} />
                                        <Route path="/resources" element={<ResourcesPage />} />
                                        {user.is_instructor
                                          ? (
                                            <Route path="/admin" element={<InstructorPage />} />
                                            )
                                          : (
                                            <></>
                                            )}
                                        <Route path="/activities/*" element={<ActivitiesPage />} />
                                    </Routes>
                                </div>
                                <Footer />
                            </div>
                        </>
                        )
                      : (
                    <></>
                        )
                    }
                </>
                )
              : (
                <>
                    <BaseHeader />
                    <BaseFooter />
                </>
                )}
        </>
  )
}

export default App
