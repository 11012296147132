import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Header, Footer } from './components/layout'
import { UserDataManager, DataSourceManager } from './managers'

import { DataSourceProvider, UserProvider } from './context'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
    <BrowserRouter>
        {/* <React.StrictMode> */}
            <UserProvider>
                <DataSourceProvider>
                    <UserDataManager />
                    <DataSourceManager />
                    <App />
                </DataSourceProvider>
            </UserProvider>
        {/* </React.StrictMode> */}
    </BrowserRouter>
)

reportWebVitals()
