import React from 'react'
import Layout from 'antd/lib/layout'
import { Card, Row, Col, Space, notification } from 'antd'

import { ReactComponent as Browser } from '../../assets/icons/browser.svg'
import { ReactComponent as Chromosome } from '../../assets/icons/chromosome.svg'
import { ReactComponent as Dna } from '../../assets/icons/dna.svg'
import { ReactComponent as Table } from '../../assets/icons/table.svg'
import { ReactComponent as RightAlign } from '../../assets/icons/right-align.svg'

import { Link, useNavigate, Outlet } from 'react-router-dom'

import { useDataSource, useUser } from '../../context'

import '../../App.css'

import { type Activity } from '../../@types/misc'

import config from '../../Config.json'

type IconComponents = Record<string, JSX.Element>

export const ActivitySelect = (): JSX.Element => {
  const navigate = useNavigate()
  const { user } = useUser()
  const icon_components: IconComponents = {
    browser: <Browser />,
    dna: <Dna />,
    chromosome:  <Chromosome />,
    table:  <Table />,
    right_align:  <RightAlign />
  }

  // filter out activities that are not available for the current user based on user.lti_course.activities
  const allowedActivities: Activity[] = config.ACTIVITIES.filter((activity) => {
    if (user?.lti_course?.activities != null) {
      return user?.lti_course?.activities.map((activity: any) => activity.name).includes(activity.name)
    }
    return []
  })

  // group activities into nested arrays of 3 each
  const activitiesGrouped = []
  for (let i = 0; i < allowedActivities.length; i += 3) {
    activitiesGrouped.push(allowedActivities.slice(i, i + 3))
  }

  return (
        <>
            <Row>
                <Col span={24}>
                    <h2>Choose one of the following activities:</h2>
                </Col>
            </Row>
            {
                activitiesGrouped.map((group, i) => (
                    <Row key={i}>
                        {
                            group.map((activity, j) => (
                                <Col key={j} span={8} className="gutter-row">
                                    <Card
                                        title={
                                            <div style={{ display: "flex", alignItems: "center", gap: "0.25em" }}>
                                                {icon_components[activity.icon]} {activity.name}
                                            </div>
                                        }
                                        bordered
                                        hoverable
                                        style={{ margin: 8 }}
                                        onClick={() => { navigate(activity.url) }}
                                    >
                                        <p>{activity.description}</p>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                ))
            }
        </>
  )
}
