import React from 'react'

import { useUser } from '../context/user_context'

import { GetUserData, GetLtiInfo, Logout } from '../api'

import { Link, useNavigate } from 'react-router-dom'

import { EulaModal, TermsModal, PrivacyModal } from '../components/legal'

export const UserDataManager = (): JSX.Element => {
  const [forceEula, setForceEula] = React.useState(false)
  const [forceTerms, setForceTerms] = React.useState(false)
  const [forcePrivacy, setForcePrivacy] = React.useState(false)

  const { user, updateUser } = useUser()

  React.useEffect(() => {
    GetUserData().then((data) => {
      updateUser(data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  React.useEffect(() => {
    setForceEula(!user?.eula_accepted)
    setForceTerms(!user?.terms_accepted)
    setForcePrivacy(!user?.privacy_accepted)
  }, [user])

  // listen for window close
  React.useEffect(() => {
    const handleWindowClose = () => {
      Logout().then(() => {
        // close the window
        window.close()
      }).catch((err) => {
        console.log(err)
      }
      )
    }
    window.addEventListener('beforeunload', handleWindowClose)
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
  }, [])

  return (
        <>
            {(user != null) && forceEula
              ? (
                <EulaModal show={true} />
                )
              : (
                <></>
                )}
            {(user != null) && forceTerms
              ? (
                <TermsModal show={true} />
                )
              : (
                <></>
                )}
            {(user != null) && forcePrivacy
              ? (
                <PrivacyModal show={true} />
                )
              : (
                <></>
                )}
        </>
  )
}
