import React from 'react'

import { useUser } from '../../context/user_context'

import type { TransferDirection } from 'antd/es/transfer'

import { Button, Transfer, Row, Col, Divider, notification } from 'antd'

import { UpdateActivities, GetUserData } from '../../api'

import { useNavigate } from 'react-router-dom'

import { type RecordType, Activity } from '../../@types/misc'

export const ActivitiesSelect = (): JSX.Element => {
  const { user, updateUser } = useUser()

  const availableActivities: RecordType[] = user!.lti_course.institution.activities.map((activity) => ({ key: activity.name, title: activity.name, description: '' }))
  const initialActivities: string[] = user!.lti_course.activities.map((activity) => (activity.name))

  const [targetActivities, setTargetActivities] = React.useState(initialActivities)
  const [selectedActivities, setSelectedActivities] = React.useState<string[]>([])

  const onActivitiesChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    setTargetActivities(nextTargetKeys)
  }

  const onActivitiesSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedActivities([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  const onActivitiesSubmit = () => {
    UpdateActivities(targetActivities).then(() => {
      notification.success({ message: 'Activities Updated!' })
      GetUserData().then((user) => {
        updateUser(user)
      }).catch((error) => {
        notification.error({ message: 'Error updating activities.' })
      })
    })
  }

  return (
        <>
            <Divider orientation="left">Select activities available to learners</Divider>
            <Row
            gutter={[24, 0]}
            justify="space-around">
                <Col span={12}>
                    <Transfer
                        showSearch
                        titles={['Activities Available', 'Activities Selected']}
                        dataSource={availableActivities}
                        targetKeys={targetActivities}
                        selectedKeys={selectedActivities}
                        render={item => item.title}
                        onChange={onActivitiesChange}
                        onSelectChange={onActivitiesSelectChange}
                        rowKey={record => record.key}
                        listStyle={{
                          width: 300,
                          height: 300
                        }}
                        />
                <br />
                <Button type="primary" onClick={onActivitiesSubmit}>Submit Changes</Button>
                </Col>
            </Row>
        </>
  )
}
