import React from 'react'

import { Tabs, type TabsProps } from 'antd'

import { useUser } from '../context/user_context'

import { useNavigate } from 'react-router-dom'

import { LookupGenesSelect, InterpretGenesSelect, TranslationTableGenesSelect, ActivitiesSelect, PharmacogenomicsReportGenesSelect } from '../components/instructor/'

import { type RecordType, Activity } from '../@types/misc'

import config from '../Config.json'

export const InstructorPage = (): JSX.Element => {
  const { user, updateUser } = useUser()

  const navigate = useNavigate()

  // stop non-admin users from accessing this page
  React.useEffect(() => {
    if (!user?.is_instructor) {
      navigate('/')
    }
  }, [user, navigate])

  const items: TabsProps['items'] = [
    { key: '1', label: 'Home', children: <ActivitiesSelect /> },
    { key: '2', label: 'Gene Lookup', children: <LookupGenesSelect /> },
    { key: '3', label: 'Translation Table', children: <TranslationTableGenesSelect /> },
    { key: '4', label: 'Gene Interpret', children: <InterpretGenesSelect /> },
    { key: '5', label: 'Pharmacogenomics Report', children: <PharmacogenomicsReportGenesSelect />}
  ]

  // filter out activities that are not available for the current instructor based on user.lti_course.institution.activities
  const availableActivities: RecordType[] = user!.lti_course.institution.activities.map((activity) => ({ key: activity.name, title: activity.name, description: '' }))

  // Filter items based on availableActivities
  const filteredItems = items.filter((item) => {
    if (item.key === '1') {
      return true;
    }
    return availableActivities.some((activity) => activity.title === item.label);
  });

  return (
        <>
          <Tabs
            defaultActiveKey="1"
            type="card"
            items={filteredItems}
          />
        </>
  )
}
