import React from 'react'
import logo from '../../assets/logo.svg'

import { Button, Menu, Row, Col, AutoComplete } from 'antd'

import { Link } from 'react-router-dom'

import { useUser } from '../../context'

import { DataSelectModal } from './data_select_modal'

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 30px',
    backgroundColor: '#f2f2f2'
  },
  logo: {
    width: '160px'
  },
  rightMenu: {
    flex: 'auto',
    minWidth: '0',
    alignItems: 'center'
  },
  menu: {
    background: 'transparent',
    fontSize: '1rem'
  },
  avatar: {
    color: '#008dff',
    backgroundColor: '#e6f7ff',
    marginLeft: '1rem'
  },
  biggerLogo: {
    maxWidth: '250px'
  },
  ttamLogo: {
    marginLeft: '.5rem',
    width: '80px'
  }
}

export const Header = (): JSX.Element => {
  const { user, updateUser } = useUser()

  return (
        <>
            <div style={styles.header}>

                        <div>
                            <Link to="/">
                                <img style={styles.logo} src={logo} alt="Test2Learn" />
                            </Link>
                        </div>

                        <div style={styles.rightMenu}>
                            <Menu
                            mode="horizontal"
                            theme="light"
                            style={styles.menu}
                            >
                                <Menu.Item key="about">
                                    <Link to="/course-info">
                                        About
                                    </Link>
                                </Menu.Item>
                                {(user != null)
                                  ? (
                                    <Menu.Item key="activities">
                                        <Link to="/activities" className="header-menu-link">
                                            Activities
                                        </Link>
                                    </Menu.Item>
                                    )
                                  : (
                                    <></>
                                    )}
                                {user?.is_instructor
                                  ? (
                                    <Menu.Item key="instructor">
                                        <Link to="/admin" className="header-menu-link">
                                            Instructor
                                        </Link>
                                    </Menu.Item>
                                    )
                                  : (
                                        <></>
                                    )}
                                <Menu.Item key="resources">
                                    <Link to="/resources" className="header-menu-link">
                                        Resources
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </div>
                        <img style={styles.biggerLogo} className="inst-logo" src={user?.lti_course.institution.logo}/>
            </div>
            <DataSelectModal />
        </>
  )
}

//
