import React from 'react'

import { useDataSource } from '../../context/data_source_context'
import { GetVariantsByGene, TranslateSample } from '../../api'

import { Space, Spin, Table, Divider, Row, Col, Button } from 'antd'

import { useUser } from '../../context/user_context'

import igv from 'igv/dist/igv.esm.min.js'

export const GenomeViewer = () => {
  // "url": "https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram",
  // "indexURL": "https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram.crai",

  const [igvViewer, setIgvViewer] = React.useState<igv.Browser>()
  const [tracks, setTracks] = React.useState<igv.Track[]>([])
  const { dataSource } = useDataSource()

  const igv_div = React.useRef(null)

  const igv_options = {
    genome: 'hg38',
    tracks: []
  }

  React.useEffect(() => {
    igv.createBrowser(igv_div.current, igv_options).then((browser: any) => {
      setIgvViewer(browser)
    })
  }, [])

  return (
        <>
            <Button onClick={() => {
              const track = {
                type: 'alignment',
                url: `https://s3.amazonaws.com/1000genomes/data/${dataSource?.anon_data_id}/alignment/${dataSource?.anon_data_id}.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram`,
                indexURL: `https://s3.amazonaws.com/1000genomes/data/${dataSource?.anon_data_id}/alignment/${dataSource?.anon_data_id}.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram.crai`,
                name: `${dataSource?.anon_data_id}-BAM`,
                format: 'cram',
                displayMode: 'EXPANDED'
              }
              // test if track already loaded based on name
              if (tracks.filter((t) => t.name === track.name).length > 0) {
                // remove from tracks
                setTracks(tracks.filter((t) => t.name !== track.name))
                igvViewer.removeTrackByName(track.name)
              } else {
                // add to tracks
                setTracks([...tracks, track])
                igvViewer.loadTrack(track)
              }
            }}>Toggle BAM</Button>
            <Divider orientation="left" >Integrated Genome Viewer</Divider>
            <div ref={igv_div} style={{ width: '100%', height: '100%' }}></div>
        </>
  )
}
